.app-header {
    height: 60px;
    position: relative;
    z-index: 10;
    width: 100%;
}

.lwsLogo{
    height: 40px;
    width: 40px;
}

.app-title {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-Title{
    margin-left: 10px;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}
span:hover{
    text-decoration: none;
}

.app-branding {
    float: left;
    height: 100%;
}

.app-options {
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
}

.app-nav ul {
    list-style-position: none;
    margin: 0;
    padding: 0;
}

.app-nav ul li {
    list-style-type: none;
    display: inline-block;
}

.app-nav ul li a {
    display: inline-block;
    line-height: 60px;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    color: rgba(0,0,0,0.65);
}